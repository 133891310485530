import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import OptionCard from "../../../components/cards/optionCard";
import {message, Space, Switch} from "antd";
import styled from "@emotion/styled";
import {Link, navigate} from "gatsby";
import DarkButton from "../../../components/buttons/darkButton";
import Seo from "../../../components/seo";
import auth from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import request from "../../../utils/request";
import * as Moment from "moment"
import {useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import {CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

const SwitchLabel = styled.span`
  line-height: 22px;
  font-size: 14px;
  font-family: Roboto-Regular, Roboto,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const Buttons = styled.div`
  margin-top: 24px;
  text-align: right;
`

const HeaderAppendText = styled.span`
  margin-left: 3px;
`

const Index = () => {
  auth.authorize()
  const dispatch = useDispatch()
  const {account} = useSelector((s: any) => s)

  const [submitting, setSubmitting] = useState(false)

  // const {data: resumeSetting, run: loadResumeSetting} = useRequest(() => {
  //   return getResumeSetting().then(rsp => rsp.data.data)
  // })
  const resumeSetting = account?.resumeSetting ?? {}
  const loadResumeSetting = () => {
    auth.load(dispatch)
  }

  const submit = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true)
    jsonRequest("/account/audit", {
      method: 'POST',
    }).then(rsp => {
      if (rsp.success) {
        message.success("认证提交成功")
        loadResumeSetting()
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setSubmitting(false)
    })
  }

  return (<>
    <PersonalLayout selected='resume' loading={account == null}>
      {resumeSetting && (
        <PersonalBody
          title='个人履历'
          append={(
            <Link to='/manual/detail/?id=43' style={{marginLeft: 12}}>
              {
                resumeSetting.auditStatus === 'auditing' && (
                  <span style={{color: '#FF5C16'}}><InfoCircleOutlined /><HeaderAppendText>认证中</HeaderAppendText></span>
                ) || (
                  resumeSetting.auditStatus === 'auditFail' && (
                    <span style={{color: '#FF5C16'}}><InfoCircleOutlined /><HeaderAppendText>认证失败</HeaderAppendText></span>
                  )
                ) || (
                  resumeSetting.auditStatus === 'auditSuccess' && (
                    <span style={{color: '#00B42A'}}><CheckCircleOutlined /><HeaderAppendText>已认证</HeaderAppendText></span>
                  )
                ) || (
                  <span style={{color: '#FF5C16'}}><InfoCircleOutlined /><HeaderAppendText>未认证</HeaderAppendText></span>
                )
              }
            </Link>
          )}
        >
          <Space direction='vertical' size={16} style={{width: '100%'}}>
            <OptionCard
              title='实名认证'
              desc={resumeSetting?.authorizedAt == null ? '选填' : `认证时间: ${Moment(resumeSetting.authorizedAt).format("YYYY-MM-DD HH:mm:ss")}`}
              onClick={resumeSetting.authorizedAt == null ? (() => navigate("/personal/resume/authentication")) : false}
            />
            <OptionCard
              title='基础信息'
              desc={
                resumeSetting?.resumeUpdatedAt == null ? '必填' : `更新时间: ${Moment(resumeSetting.resumeUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              required={resumeSetting?.resumeUpdatedAt == null}
              onClick={() => navigate("/personal/resume/info")}
            />
            <OptionCard
              title='个人简介'
              desc={
                resumeSetting?.introductionUpdatedAt == null ? '必填' : `更新时间: ${Moment(resumeSetting.introductionUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              required={resumeSetting?.introductionUpdatedAt == null}
              onClick={() => navigate("/personal/resume/introduction")}
            />
            <OptionCard
              title='学习经历'
              desc={
                resumeSetting.educationUpdatedAt == null ? '选填': `更新时间: ${Moment(resumeSetting.educationUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              options={(
                <Space size={6}>
                  <SwitchLabel>隐藏/显示:</SwitchLabel>
                  <Switch
                    checked={resumeSetting.isShowEducation}
                    onChange={value => {
                      const body = new FormData()
                      body.set("value", value ? 'true' : 'false')
                      request("/account/isShowEducation", {
                        method: 'PUT',
                        body,
                      }).then(() => {
                        loadResumeSetting()
                      })
                    }}
                  />
                </Space>
              )}
              onClick={() => navigate("/personal/resume/education")}
            />
            <OptionCard
              title='工作经历'
              desc={
                resumeSetting.professionalExperienceUpdatedAt == null ? '选填': `更新时间: ${Moment(resumeSetting.professionalExperienceUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              options={(
                <Space size={6}>
                  <SwitchLabel>隐藏/显示:</SwitchLabel>
                  <Switch
                    checked={resumeSetting.isShowProfessionalExperience}
                    onChange={value => {
                      const body = new FormData()
                      body.set("value", value ? 'true' : 'false')
                      request("/account/isShowExperience", {
                        method: 'PUT',
                        body,
                      }).then(() => {
                        loadResumeSetting()
                      })
                    }}
                  />
                </Space>
              )}
              onClick={() => navigate('/personal/resume/experience')}
            />
            <OptionCard
              title='专业技能'
              desc={
                resumeSetting?.skillUpdatedAt == null ? '必填': `更新时间: ${Moment(resumeSetting.skillUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              required={resumeSetting?.skillUpdatedAt == null}
              onClick={() => navigate('/personal/resume/skill')}
            />
            <OptionCard
              title='项目案例'
              desc={
                resumeSetting?.caseUpdatedAt == null ? '必填' : `更新时间: ${Moment(resumeSetting.caseUpdatedAt).format("YYYY-MM-DD HH:mm:ss")}`
              }
              required={resumeSetting?.caseUpdatedAt == null}
              onClick={() => navigate('/personal/resume/case')}
            />
          </Space>
          {
            resumeSetting.auditStatus === 'auditing' || resumeSetting.auditStatus === 'auditSuccess' || (
              // todo Modal.confirm
              <Buttons>
                <DarkButton
                  loading={submitting}
                  onClick={submit}
                  disabled={resumeSetting?.introductionUpdatedAt == null || resumeSetting?.resumeUpdatedAt == null || resumeSetting?.skillUpdatedAt == null || resumeSetting?.caseUpdatedAt == null}
                >提交认证</DarkButton>
              </Buttons>
            )
          }
        </PersonalBody>
      )}
    </PersonalLayout>
  </>)
}

export default Index;

export const Head = () => <Seo />
